import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
window.firebaseConfig = {
  apiKey: "AIzaSyDjjF-J978xfpoLEX0T0TvkD_q9IwVWdBU",
  authDomain: "lab-rad-doctor-app.firebaseapp.com",
  projectId: "lab-rad-doctor-app",
  storageBucket: "lab-rad-doctor-app.appspot.com",
  messagingSenderId: "694268856999",
  appId: "1:694268856999:web:a2fec5409b6caae39d6982",
  measurementId: "G-GDDC9JS5HW"
};

// Initialize Firebase
window.initializeApp = initializeApp;
window.app = initializeApp(window.firebaseConfig);

window.FireAuth = getAuth();
window.User = window.FireAuth.currentUser;
window.signInWithPhoneNumber = signInWithPhoneNumber;
window.signOut = signOut;

window.RecaptchaVerifier = RecaptchaVerifier;
window.FireAuth.languageCode = 'ar';
const messaging = getMessaging(window.app);
const is_auth = $('meta[name="is_auth"]').attr('content');
const fcm_token = $('meta[name="fcm_token"]').attr('content');
if (is_auth == true) {
  console.log('is_auth');
  if (!fcm_token) {
    console.log('get_fcm_token');
    getToken(messaging, { vapidKey: 'BHkM5Hl8Df-9ZG6JN8POn2hvIRw7O3Z3tllKJwwFvdVpigUIm4rW9srYjeeZihitjgEMAP9JWX3PGyFN_xKAdcU' })
      .then(function (response) {
        // console.log(response);
        const url = $('meta[name="post-url"]').attr('content');
        // const url=document.querySelector("meta[name='post-url']").getAttribute("content")
        // console.log(url);
        const csrf = $('meta[name="csrf-token"]').attr('content');
        console.log(csrf);
        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          }
        });
        $.ajax({
          url: url,
          type: 'POST',
          data: {
            token: response
          },
          dataType: 'JSON',
          success: function (response) {
            console.log('Device token saved.');
          },
          error: function (error) {
            console.log(error);
          },
        });
      }).catch(function (error) {
        console.error(error);
      });
  }
}
onMessage(messaging, function (payload) {
  const title = payload.notification.title;
  const options = {
    body: payload.notification.body,
    icon: payload.notification.icon,
  };
  new Notification(title, options);
});
